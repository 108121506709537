export const stopIn =
  "m163.292969 475.394531-126.691407-126.695312c-.828124-.828125-1.292968-1.953125-1.292968-3.121094v-179.15625c0-1.167969.464844-2.292969 1.292968-3.121094l126.691407-126.695312c.828125-.828125 1.949219-1.292969 3.121093-1.296875h179.171876c1.171874.003906 2.292968.46875 3.121093 1.296875l126.691407 126.695312c.828124.828125 1.292968 1.953125 1.292968 3.121094v179.15625c0 1.167969-.464844 2.292969-1.292968 3.121094l-126.691407 126.695312c-.828125.828125-1.949219 1.292969-3.121093 1.296875h-179.171876c-1.171874-.003906-2.292968-.46875-3.121093-1.296875zm0 0";

export const stopOut =
  "m358.382812 0h-204.765624c-2.339844 0-4.585938.929688-6.242188 2.585938l-144.789062 144.789062c-1.65625 1.65625-2.585938 3.902344-2.585938 6.242188v204.765624c0 2.339844.929688 4.585938 2.585938 6.242188l144.789062 144.789062c1.65625 1.65625 3.902344 2.585938 6.242188 2.585938h204.765624c2.339844 0 4.585938-.929688 6.242188-2.585938l144.789062-144.789062c1.65625-1.65625 2.585938-3.902344 2.585938-6.242188v-204.765624c0-2.339844-.929688-4.585938-2.585938-6.242188l-144.789062-144.789062c-1.65625-1.65625-3.902344-2.585938-6.242188-2.585938zm0 0";

export const triangleIn = 
"m39.367188 423.738281c-1.414063.050781-2.722657-.757812-3.304688-2.046875-.972656-1.535156-.980469-3.488281-.023438-5.03125l216.652344-379.253906c.617188-1.273438 1.910156-2.082031 3.324219-2.082031s2.707031.808593 3.324219 2.082031l216.65625 379.25c.957031 1.542969.949218 3.496094-.019532 5.03125-.585937 1.292969-1.890624 2.101562-3.308593 2.050781zm0 0";

export const triangleOut = 
"m222.03125 19.890625-216.652344 379.25c-7.105468 12.261719-7.175781 27.375-.183594 39.699219 6.996094 12.328125 20 20.019531 34.171876 20.207031h433.300781c14.171875-.1875 27.175781-7.878906 34.171875-20.207031 6.992187-12.328125 6.921875-27.4375-.183594-39.699219l-216.652344-379.25c-6.90625-12.277344-19.898437-19.875-33.984375-19.875-14.089843 0-27.082031 7.597656-33.988281 19.875zm0 0";

export const pentagonIn = "m32 8-25 19v30h50v-30z";

export const pentagonOut = "m30.791 3.917-27 20.483a2 2 0 0 0 -.791 1.593v33.007a2 2 0 0 0 2 2h54a2 2 0 0 0 2-2v-33.007a2 2 0 0 0 -.791-1.593l-27-20.483a2 2 0 0 0 -2.418 0z";